* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  max-width: 100vw;
}

.image-gallery {
  width: 100%;
  max-width: 700px;
  max-height: auto;
  padding: 0 2rem;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}

